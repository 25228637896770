@font-face {
    font-family: 'Mulish';
    src: url('./Mulish-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Mulish';
    src: url('./Mulish-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Mulish';
    src: url('./Mulish-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Mulish';
    src: url('./Mulish-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Mulish';
    src: url('./Mulish-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Mulish';
    src: url('./Mulish-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: 'Mulish';
    src: url('./Mulish-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Mulish';
    src: url('./Mulish-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'Mulish';
    src: url('./Mulish-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Mulish';
    src: url('./Mulish-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Mulish';
    src: url('./Mulish-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Mulish';
    src: url('./Mulish-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Mulish';
    src: url('./Mulish-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Mulish';
    src: url('./Mulish-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Mulish';
    src: url('./Mulish-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Mulish';
    src: url('./Mulish-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

